@import "mixins";

///* Notice Board */
//.etp-layout-notice.etp-style-boundary.prose {
//  display: flex;
//  flex-direction: row;
//  flex-wrap: wrap;
//  align-content: center;
//  justify-content: center;
//  gap: 10px;
//
//  & > * {
//     display: flex;
//    min-height: 320px;
//    max-height: 320px;
//    min-width: 320px;
//    max-width: 320px;
//    margin:10px;
//    padding:1em;
//    &:hover {
//      border: 1px dotted #ccc;
//    }
//  }
//}

/* Carousel */

.etp-not-read-only {
  /* Grid */
  .etp-layout-carousel.etp-style-boundary.prose {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    gap: 0;
    margin: 0;
    padding: 0;

    @include proportional-text;

    & > * {
      display: flex;
      border: 1px dotted rgba(0, 0, 0, 1.0);
      border-radius: 4px;
      min-height: 340px;
      max-height: 340px;
      min-width: 340px;
      max-width: 340px;
      margin: 0;
      padding:0;
      flex-direction: column;
      justify-content: center;
      text-align:center;
      transition: border 0.5s;

      &:hover {
        border-color: #999;
      }
    }
  }

  @media (min-width: 768px) {
    .etp-landscape {
      .etp-layout-carousel.etp-style-boundary.prose > * {
        min-height: 340px;
        max-height: 340px;
        min-width: 680px;
        max-width: 680px;
      }
    }
    .etp-portrait {
      .etp-layout-carousel.etp-style-boundary.prose > * {
        min-height: 680px;
        max-height: 680px;
        min-width: 340px;
        max-width: 340px;
      }
    }

    .etp-square {
      .etp-layout-carousel.etp-style-boundary.prose > * {
        min-height: 340px;
        max-height: 340px;
        min-width: 340px;
        max-width: 340px;
      }
    }


  }

}


.etp-read-only {
  .etp-layout-carousel.etp-style-boundary.prose {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > *[data-placeholder] {
      display: none;
    }
    > * {
      margin: 0;
      padding:0;
    }

    @include proportional-text;

    img {
      max-width: calc(var(--etp-page-width) / 1.2);
      height:auto;
      max-height: calc(var(--etp-page-height) / 1.2);

    }

  }

  .etp-layout-carousel > * {
    position: absolute;
    opacity: 0;
    transition: opacity 0.6s ease-in-out;

    &.etp-carousel-active {
      opacity: 1;
    }
  }

}



/* Grid */
.etp-layout-grid.etp-style-boundary.prose {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  max-height: fit-content;
  gap: 16px;
  margin: 16px;
  padding: 0;

  & > * {
    display: flex;
    border: 1px dotted rgba(0, 0, 0, 0.0);
    min-height: 315px;
    max-height: 315px;
    min-width: 315px;
    max-width: 315px;
    margin: 0;
    padding: 1em;
    transition: border 0.5s;

    &:hover {
      border-color: #999;
    }
  }
}


/* Business as Usual */
.etp-layout-business-as-usual.etp-style-boundary.prose {
  background: none;
  padding-top:2em;
  padding-bottom:6em;
  @include responsive-text;


  h1 {
    text-align: center;
    @media (min-width: 768px) {
      font-size: 4em;
    }


    @media (min-width: 1024px) {
      font-size: 6em;
    }

  }
}


/* Heroic */

.etp-layout-hero.etp-style-boundary.prose {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100dvh;
  max-height: 100dvh;

  & > * {
    display: flex;
  }

  h1 {
    font-family: 'Bebas Neue', sans-serif !important;
    font-weight: 800;
    font-size: calc(var(--etp-page-width) / 10);
    text-align: center;

    * {
      font-family: 'Bebas Neue', sans-serif !important;
    }

    //@media (min-width: 768px) {
    //  font-size: 8em;
    //}
    //
    //
    //@media (min-width: 1024px) {
    //  font-size: 10em;
    //}


    /*color: #00dd00;*/
    /*filter: sepia(0.5);*/
  }
}


/* Heroic Article */

.etp-layout-hero-article.etp-style-boundary.prose {
  padding-top:2em;
  padding-bottom:6em;
  @include responsive-text;
  text-align: left;

  > *:is(h1,h2,h3) {
    text-align: left;
  }

  h1 {
    font-family: 'Bebas Neue', sans-serif !important;
    font-weight: 800;
    font-size: 4em;
    text-align: center;
    margin-bottom: 0.5em;

    * {
      font-family: 'Bebas Neue', sans-serif !important;
    }

    @media (min-width: 768px) {
      font-size: 8em;
    }


    @media (min-width: 1024px) {
      font-size: 10em;
    }


    /*color: #00dd00;*/
    /*filter: sepia(0.5);*/
  }
}

.etp-landscape .etp-layout-hero-article.etp-style-boundary.prose {
  display: flex;
  flex-direction: column;
  justify-content: left;
  min-height: 100vh;

  h1 {
    display: inline;
    text-align: center;
  }
}


/* Meme */

.etp-layout-meme.etp-style-boundary.prose {
  :is(h1,h2,h3, strong) {
    font-family: 'Bebas Neue', sans-serif !important;
    font-weight: 800;
    text-align: center;
    color: white !important;
    background-color: black;
  }

  strong {
    display: inline;
  }


}

/* Article */

.etp-layout-article.etp-style-boundary.prose  {
  padding-top:2em;
  padding-bottom:6em;
  @include responsive-text;
}

/* None */

.etp-layout-none.etp-style-boundary.prose  {
  padding-top:2em;
  padding-bottom:6em;
  @include responsive-text;
}

/* Document */

.etp-layout-document.etp-style-boundary.prose  {
  padding-top:2em;
  padding-bottom:6em;
  @include responsive-text;
}
