@mixin responsive-text {
    font-size: 1rem;
    padding-top: 1rem;
    padding-left: 3vw;
    padding-right: 3vw;
    @media (min-width: 600px) {
        font-size: 1rem;
    }
}

@mixin fixed-text {
    white-space: pre-wrap;
    font-size: calc( 94vw / (600 / 24));
    padding-top: 3vw;
    padding-left: 3vw;
    padding-right: 3vw;

    @media (min-width: 600px) {
        font-size: 24px;
    }
    

}

@mixin proportional-text {
    h1 {
        font-weight: bolder;
        font-size: calc(var(--etp-page-width) / 10);
        text-align: center;
    }

    h2 {
        font-weight: bolder;
        font-size: calc(var(--etp-page-width) / 12);
        text-align: center;
    }

    h3 {
        font-weight: bold;
        font-size: calc(var(--etp-page-width) / 16);
        text-align: center;
    }

    p {
        font-size: calc(var(--etp-page-width) / 20);
    }

}
