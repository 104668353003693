@tailwind base;
@tailwind components;
@tailwind utilities;


/* https://www.sessions.edu/color-calculator/ */
dummy-fg {
  color: hsl(190, 38%, 31%); /* primary */
  color: hsl(160, 38%, 31%); /* secondary */
  color: hsl(340, 38%, 31%);  /* accent */
}
@layer base {
  :root {
    --background: 0 0% 99%;
    --foreground: 190, 0%, 20%;

    --card: 0 0% 100%;
    --card-foreground: 190 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 190 84% 4.9%;

    --primary: 190, 38%, 31%;
    --primary-foreground: 190 10% 98%;

    --secondary: 160 35% 49%;
    --secondary-foreground: 160 40% 90%;

    --muted: 190 40% 96.1%;
    --muted-foreground: 190.4 16.3% 46.9%;

    --accent: 340 40% 96.1%;
    --accent-foreground: 340 47.4% 11.2%;

    --destructive: 340 84.2% 60.2%;
    --destructive-foreground: 340 40% 98%;

    --border: 190 31.8% 91.4%;
    --input: 190 31.8% 91.4%;
    --ring: 190 84% 4.9%;

    --radius: 0.5rem;

    --edt-menu-text-color: #333;
    --edt-menu-text-inverse-color: #EEE;
    --edt-menu-bg-color: #FFF;

    --novel-highlight-default: #ffffff;
    --novel-highlight-purple: #f6f3f8;
    --novel-highlight-red: #fdebeb;
    --novel-highlight-yellow: #fbf4a2;
    --novel-highlight-blue: #c1ecf9;
    --novel-highlight-green: #acf79f;
    --novel-highlight-orange: #faebdd;
    --novel-highlight-pink: #faf1f5;
    --novel-highlight-gray: #f1f1ef;

    --etp-max-width: 600px;
  }

/*  .dark {*/
/*    --background: 222.2 84% 4.9%;*/
/*    --foreground: 210 40% 98%;*/

/*    --card: 222.2 84% 4.9%;*/
/*    --card-foreground: 210 40% 98%;*/

/*    --popover: 222.2 84% 4.9%;*/
/*    --popover-foreground: 210 40% 98%;*/

/*    --primary: 262, 50%, 60%;*/
/*    --primary-foreground: 262 40% 98%;*/

/*    --secondary: 217.2 32.6% 17.5%;*/
/*    --secondary-foreground: 210 40% 98%;*/

/*    --muted: 217.2 32.6% 17.5%;*/
/*    --muted-foreground: 215 20.2% 65.1%;*/

/*    --accent: 217.2 32.6% 17.5%;*/
/*    --accent-foreground: 210 40% 98%;*/

/*    --destructive: 0 62.8% 30.6%;*/
/*    --destructive-foreground: 210 40% 98%;*/

/*    --border: 217.2 32.6% 17.5%;*/
/*    --input: 217.2 32.6% 17.5%;*/
/*    --ring: 212.7 26.8% 83.9%;*/

/*    --novel-highlight-default: #000000;*/
/*    --novel-highlight-purple: #3f2c4b;*/
/*    --novel-highlight-red: #5c1a1a;*/
/*    --novel-highlight-yellow: #5c4b1a;*/
/*    --novel-highlight-blue: #1a3d5c;*/
/*    --novel-highlight-green: #1a5c20;*/
/*    --novel-highlight-orange: #5c3a1a;*/
/*    --novel-highlight-pink: #5c1a3a;*/
/*    --novel-highlight-gray: #3a3a3a;*/
/*  }*/
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
