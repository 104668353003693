@import url('https://fonts.googleapis.com/css2?family=Indie Flower&display=swap');

/* Some positioning and ratios */
.sticky-container {
    max-width: 18rem;
    position: relative;
}

.sticky-outer {
    display: flex;
    padding-top: 92.5925926%;
    position: relative;
    min-width:100%;
    width: 100%;
}

.sticky-outer-outer {
    display:inline-block !important;
    min-width:100%;
}

.sticky-note {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/* Shadow behind the sticky note */
.sticky-note:before {
    box-shadow: -2px 2px 20px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.25);
    content: '';
    width: 90%;
    left: 5px;
    height: calc(70% - 10px);
    position: absolute;
    top: 30%;
}

/* The sticky note itself */
.sticky-content {
    background: linear-gradient(
            180deg,
            hsl(60, 90%, 56%) 0%,
            hsl(60, 90%, 56%) 12%,
            hsl(60, 90%, 52%) 75%,
            hsl(60, 90%, 55%) 100%
    );
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: 'Indie Flower', cursive;
    font-size: 2rem !important;
    line-height: 2rem;
    padding: 2rem;
    text-align: center;
    clip-path: url(#stickyClip);
}


/* Add responsiveness */
@media screen and (min-width: 640px) {
    .sticky-content {
        font-size: 1.5rem;
    }

    .container-inner {
        width: 50%;
    }
}

@media screen and (min-width: 768px) {
    .sticky-content {
        font-size: 1.5rem;
    }

    .container-inner {
        width: 50%;
    }
}

@media screen and (min-width: 1024px) {
    .sticky-content {
        font-size: 1.875rem;
    }

    .container-inner {
        width: 25%;
    }
}
